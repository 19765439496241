<template>
  <div class="homePage">
    <HomeHeader :companyOption='companyOption' @changeCompany="changeCompany"></HomeHeader>
    <div class="contentArea">
      <!-- <div class="leftLayout">
        <div class="noticeList">
          <p class="title">待办通知</p>
          <div class="cells" :style="'height:'+noticeHeight+'px;'">

            <div class="cell" v-for="(item,index) in messageList" :key="index" @click="messageJump(item)">
              <div class="left">
                <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
                <span>{{item.serviceName}}</span>
              </div>
              <span class="notice" v-if="item.msgValue">{{item.msgValue}}</span>
            </div>

          </div>
        </div>
      </div> -->
      <div class="rightLayout">
        <div class="topApp" ref="topApp">
          <p class="title">应用中心</p>
          <div class="appBox">
            <div class="oneApp" v-for="(item, index) in productsMenu" :key="index" @click="platformJump(item)">
              <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
              <div>
                <p class="p1">{{ item.productName }}</p>
                <p class="p2">{{item.productDesc}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="dataArea">
          <el-tabs type="border-card">
            <el-tab-pane label="仓库情况展示" :lazy="true"></el-tab-pane>
          </el-tabs>
          <div class="charts">
            <div id="geoChart" ref="geoChart" :style="'width: 100%; height: '+echartHeight+'px'"></div>
            <div class="table">
              <div class="title" style="font-size:16px;margin-bottom:10px;text-align:left;font-weight:600;">{{ currentWarehouse }}仓库品种库存情况</div>
              <el-table :data="warehouseTable" border :height="echartHeight-30">
                <el-table-column prop="goodsName" label="商品" header-align="center" align="center"> </el-table-column>
                <el-table-column prop="goodsSpec" label="规格" header-align="center" align="center"> </el-table-column>
                <el-table-column prop="lot" label="批号" header-align="center" align="center"> </el-table-column>
                <el-table-column prop="stockQty" label="库存数" header-align="center" align="center">
                  <template slot-scope="scope">{{scope.row.stockQty}}</template>
                </el-table-column>
                <el-table-column prop="leftDay" label="近效期" header-align="center" align="center">
                  <template slot-scope="scope">{{scope.row.leftDay}}天</template>
                </el-table-column>
                <el-table-column prop="stockDay" label="库龄" header-align="center" align="center">
                  <template slot-scope="scope">{{scope.row.stockDay}}天</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import newEcharts from 'echarts';
  import HomeHeader from '@/components/homeHeader.vue';
  import toOther from './mixins/toOther';
  import 'echarts/map/js/china';
  export default {
    name: 'marketingDigitalPlatform',
    components: {
      HomeHeader
    },
    data() {
      return {
        defaultPng: require('../../assets/personalPage/icon3.png'),
        currentTenantGuid: null,
        currentStaffGuid: null,
        companyOption: [],
        organisationName: '--',
        positionName: '--',
        messageList: [],
        productsMenu: [],
        echartHeight: 0,
        noticeHeight: 0,
        currentWarehouse: null,
        warehouseTable:[]
      };
    },
    mixins: [toOther],
    created() {
      document.title = '个人中心';
      localStorage.loginType = '2';
      this.getTenenants();
    },
    mounted() {
        this.noticeHeight = window.innerHeight - 170
    },
    methods: {
      //获取消息列表
      // getMessageList() {
      //   var menuList = JSON.parse(localStorage.menuList);
      //   var tenantGuid = this.currentTenantGuid;
      //   var currentStaffGuid = this.currentStaffGuid;
      //   this.api.get('/ms-sales-perform-service/msg-group/get?menuGuid=' + menuList[0].guid + '&tenantGuid=' +
      //     tenantGuid + '&staffGuid=' + currentStaffGuid).then((res) => {
      //     if (res.data.code == '00000') {
      //       this.messageList = res.data.data;
      //     } else {
      //       this.$message.error(res.data.msg);
      //     }
      //   });
      // },
      //切换公司
      changeCompany(guid) {
        this.getMenuList(guid);
      },
      getMenuList(tenantGuid) {
        let self = this;
        self.api
          .get('/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' + `appType=01&tenantGuid=` +
            tenantGuid + `&appSideIdentifier=QYD`)
          .then((res) => {
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            if (res.data.data[0].menuName == '主页') {
              this.getCurrentstaff(tenantGuid,res.data.data[0].path)
              // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
              //   .username +
              //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
            }
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
      },
      getCurrentstaff(tenantGuid,path) {
        let self = this;
        self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then((res) => {
          if (res.data.code == '00000') {
            self.$store.commit('$_setUserId', res.data.data.userGuid);
            localStorage.setItem('userInfo', JSON.stringify(res.data.data));
             window.location.replace(location.origin + path + "?username=" + self.$route.query.username +'&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid);
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
      },
      //获取当前用户公司信息
      getTenenants() {
        var userGuid = localStorage.userId;
        this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then((res) => {
          if (res.data.code == '00000') {
            this.companyOption = res.data.data.records;
            this.currentTenantGuid = this.$route.query.currentTenantGuid;
            this.companyOption.forEach((item) => {
              if (item.tenantGuid == this.currentTenantGuid) {
                this.currentStaffGuid = item.staffGuid;
              }
            });
            //this.getMessageList();
            this.getOrgAndProduct();

          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      //获取当前公司下的菜单
      getOrgAndProduct() {
        this.api
          .get(
            '/ms-personnel-service/staff-home/org-and-product?staffGuid=' + this.currentStaffGuid +
            '&tenantGuid=' + this.currentTenantGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              this.organisationName = res.data.data.organisationName;
              this.positionName = res.data.data.positionName;
              this.productsMenu = res.data.data.products;
              this.productsMenu.forEach(item=>{
                if(item.icon){
                  let obj = JSON.parse(item.icon)
                  this.$set(item,'iconUrl',obj.path)
                }
              })
              //渲染应用产品后，渲染图表
              this.$nextTick(() => {
                let height = this.$refs.topApp.offsetHeight
                this.echartHeight = window.innerHeight - height - 190
                this.getWarehouse();
              })

            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //切换图标tab
      changeTab(e) {
        if (e.label == '仓库情况展示') {
          this.getWarehouse() //近期销售额
        } else {}
      },
    //获取仓库数据
    getWarehouse() {
      this.api
        .get('/ms-logistics-trace-service/front/report/get-warehouse-list?tenantGuid=' + this.currentTenantGuid)
        .then((res) => {
          if (res.data.code == '00000') {
            this.warehouseData = res.data.data;
            this.initGeoChart();
            if (this.warehouseData.length) {
              this.getStockData(this.warehouseData[0]);
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //获取库存情况信息
    //
    getStockData(warehouse) {
      this.currentWarehouse = warehouse.warehouseName;
      this.api
        .get(
          '/ms-logistics-trace-service/front/report/get-pc-stock-info-table?tenantGuid=' +
            this.currentTenantGuid +
            '&warehouseGuid=' +
            warehouse.guid,
        )
        .then((res) => {
          console.log('???', res);
          if (res.data.code == '00000') {
            this.warehouseTable = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //初始化图表1
    initGeoChart() {
      let geoChart = this.$echarts.init(document.getElementById('geoChart'));
      geoChart.clear();
      geoChart.off('click');
      let outdata = [];
      //处理地图数据
      this.warehouseData.forEach((item) => {
        outdata.push({
          name: item.province,
          warehouseName: item.warehouseName,
          guid: item.guid,
          value: 100,
        });
      });
      let geoCoordMap = {
        新疆省: [86.61, 40.79],
        西藏省: [89.13, 30.66],
        黑龙江省: [128.34, 47.05],
        吉林省: [126.32, 43.38],
        辽宁省: [123.42, 41.29],
        内蒙古: [112.17, 42.81],
        北京省: [116.4, 40.4],
        宁夏省: [106.27, 36.76],
        山西省: [111.95, 37.65],
        河北省: [115.21, 38.44],
        天津省: [117.04, 39.52],
        青海省: [97.07, 35.62],
        甘肃省: [103.82, 36.05],
        山东省: [118.01, 36.37],
        陕西省: [108.94, 34.46],
        河南省: [113.46, 34.25],
        安徽省: [117.28, 31.86],
        江苏省: [120.26, 32.54],
        上海省: [121.46, 31.28],
        四川省: [103.36, 30.65],
        湖北省: [112.29, 30.98],
        浙江省: [120.15, 29.28],
        重庆省: [107.51, 29.63],
        湖南省: [112.08, 27.79],
        江西省: [115.89, 27.97],
        贵州省: [106.91, 26.67],
        福建省: [118.31, 26.07],
        云南省: [101.71, 24.84],
        台湾: [121.01, 23.54],
        广西省: [108.67, 23.68],
        广东省: [113.98, 22.82],
        海南省: [110.03, 19.33],
        澳门: [113.54, 22.19],
        香港: [114.17, 22.32],
      };
      // 匹配省市
      var convertData = function (data) {
        var res = [];
        console.log(data)
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              warehouseName: data[i].warehouseName,
              guid: data[i].guid,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        console.log(res)
        return res;
      };
      let option = {
        title: {
          text: '全国仓库分布图',
          left: '10px',
          textStyle: {
            color: '#4D4D4D',
            fontWeight: '600',
            fontSize: 16,
          },
          top: '2%',
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            if (params.value) {
              console.log(params)
              return params.data.warehouseName + '<br/>' + params.data.name;
            } else {
              return params.name;
            }
          },
        },        
          geo: {
            map: 'china',
            show: true,
            roam: false,
            label: {
              show: false,
              emphasis: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                areaColor: '#4fa1a4',
                borderColor: '#e5e5e5',
                borderWidth: 1,
              },
            },
            itemStyle: {
              areaColor: '#f4f4f7',
              borderColor: '#e5e5e5',
              borderWidth: 1,
            },
          },
        series: [
          {
            type: 'map',
            map: 'china',
            aspectScale: 0.75,
            geoIndex: 0,
            zoom:1,
            label: {
              show: false,
              emphasis: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                areaColor: '#f4f4f7',
                borderColor: '#e5e5e5',
                borderWidth: 1,
              },
            },
            itemStyle: {
              areaColor: '#f4f4f7',
              borderColor: '#e5e5e5',
              borderWidth: 1,
            },
          },
          {
            name: '点',
            type: 'scatter',
            symbolSize: 6,
            coordinateSystem: 'geo',
            showEffectOn: 'emphasis',
            rippleEffect: {
              brushType: 'stroke',
            },
            hoverAnimation: true,
            label: {
              formatter: '{b}',
              position: 'right',
              fontSize: 10,
              show: true,
            },
            itemStyle: {
              color: '#649fa3',
            },
            data: convertData(outdata),
          },
        ],
      };
      geoChart.setOption(option);
      window.addEventListener('resize', function () {
        geoChart.resize();
      });

      var that = this;
      geoChart.on('click', function (params) {
        console.log(params); //此处写点击事件内容
        if (params.componentSubType == 'scatter') {
          that.getStockData(params.data);
        }
      });
    },


    },
  };
</script>


<style scoped>
.topApp p,
.noticeList p{
  padding: 0;
  margin: 0;
}
  .homePage {
    background: #F6F7FA;
    min-height: 100vh;
    width: 100%;
    padding: 0.8rem 1rem 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .logoArea {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1.5rem;
  }

  .logoArea img {
    height: 2.125rem;
    margin-right: 1.875rem;
  }

  .logoArea span {
    font-size: 1.25rem;
    color: #323233;
  }

  .contentArea {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  /* 左侧 */
  .leftLayout {
    width: 22%;
    padding: 1.25rem 1.5rem;
    box-sizing: border-box;
    background: #fff;
  }

  /* .companyName {
    width: 70%;
    margin: 0 auto;
    font-size: 1.1rem;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 0.375rem;
    font-weight: 600;
  } */
    .userInfo /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-size: 18px;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 6px;
    font-weight: 500;
    /* width: 82%; */
  }

  .userInfo /deep/ .el-select-dropdown__item.selected {
    color: #4fa1a4;
  }

  .userInfo /deep/ .el-select .el-input .el-select__caret {
    height: auto;
    font-size: 1.1rem;
    color: #4D4D4D;
  }
  .userInfo {
    text-align: center;
    /* height: 40%; */
    padding-bottom: 14%;
  }

  .userInfo .userIcon {
    margin-top: 2rem;
    margin-bottom: 1.0625rem;
  }

  .userInfo .userIcon img {
    width: 6rem;
    height: 6rem;
    border: 0.75rem solid rgb(223, 225, 230, 0.2);
    border-radius: 50%;
    box-sizing: border-box;
  }

  .userInfo .position {
    color: #4D4D4D;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .noticeList .title {
    margin-bottom: 1rem;
    padding-bottom: 16px;
    font-size: 1.1rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    text-align: left;
    font-weight: 500;
  }

  .noticeList .cells {
    overflow-y: auto;
  }

  .noticeList .cell {
    padding: 0.75rem;
    box-sizing: border-box;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #323233;
    border-bottom: 1px solid rgba(116, 116, 116, 0.06);
    cursor: pointer;
  }

  .noticeList .cell:hover {
    transition: all 0.5s;
    color: #4fa1a4;
  }

  .noticeList .cell .left {
    display: flex;
    align-items: center;
  }

  .noticeList .cell img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .noticeList .cell .notice {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    background: #FF4831;
    border-radius: 50%;
    font-size: 0.625rem;
    color: #fff;
  }
  .noticeList .cell span{
    font-size: 1.1rem;
    word-break: keep-all;
    text-align: left;
  }

  /* 右侧 */
  .rightLayout {
    flex: 1;
    padding-left: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .topApp {
    background: #fff;
    padding: 1.25rem 1.5rem 0.25rem 1.5rem;
  }

  .topApp .title {
    font-size: 1.1rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-weight: 500;
  }

  .topApp .appBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .topApp .oneApp {
    width: 23%;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    background: #F6F7FA;
    margin-right: 2%;
    margin-bottom: 1rem;
    cursor: pointer;
    box-sizing: border-box;
  }

  .topApp .oneApp:hover {
    transition: all 0.5s;
    box-shadow: 0px 0 0.625rem 3px rgba(0, 0, 0, 0.1);
  }

  .topApp .oneApp img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  .topApp .oneApp .p1 {
    font-size: 1.1rem;
    margin-bottom: 4px;
    font-weight:400;
  }

  .topApp .oneApp .p2 {
    font-size: 0.8rem;
    color: #6E6E6E;
  }

  .dataArea {
    margin-top: 1rem;
    flex: 1;
    width: 100%;
    background: #fff;
  }

  .dataArea .charts {
    padding: 0 1.5rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .dataArea /deep/ .el-tabs__item {
    width: 14.5rem;
    color: #323233;
    font-size: 1rem;
    height: 3rem;
    line-height: 3rem;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #323233;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #323233;
    font-weight: 400;
    box-shadow: 2px 0 0.5rem 1px rgba(0, 0, 0, 0.1);
  }

  .dataArea /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: 0 0 0 0;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border: none;
    font-size: 1.1rem;
  }
</style>
